import Vue from 'vue'
import VueRouter from 'vue-router'
import { authGuard } from "../auth";
import { wrap, wrapPositions, wrapOceanBoltAIS } from '@/helpers/RemoteData'
import Dashboard from '@/views/Dashboard.vue'
import Positions from '@/views/Positions.vue'
import Commodity from '@/views/Commodity.vue'
import ViterraForecasts from '@/views/ViterraForecasts.vue'
import Documentation from '@/views/Documentation.vue'
import ForecastsDocumentation from '@/views/ForecastsDocumentation.vue'
import OceanboltAIS from '@/views/OceanboltAIS.vue'
import OceanboltAISSupraHandy from '@/views/OceanboltAISSupraHandy.vue'

Vue.use(VueRouter)


const routes = [
  {
    path: '/',
    component: wrap(Dashboard),
    props: true,
    beforeEnter: authGuard
  },
  {
    path: '/forecasts-documentation',
    component: ForecastsDocumentation,
    props: true,
    beforeEnter: authGuard
  },
  {
    path: '/documentation',
    component: Documentation,
    props: true,
    beforeEnter: authGuard
  },
  {
    path: '/ais',
    component: wrapOceanBoltAIS(OceanboltAIS),
    props: true,
    beforeEnter: authGuard
  },
  {
    path: '/aissuprahandy',
    component: wrapOceanBoltAIS(OceanboltAISSupraHandy),
    props: true,
    beforeEnter: authGuard
  },
  {
    path: '/positions',
    component: wrapPositions(Positions),
    props: true,
    beforeEnter: authGuard
  },
  {
    path: '/forecasts/:slug',
    component: wrap(ViterraForecasts),
    props: true,
    beforeEnter: authGuard
  },
  {
    path: '/:name',
    component: wrap(Commodity),
    props: true,
    beforeEnter: authGuard
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
