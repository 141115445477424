<template>
  <b-container fluid v-if="commodity">
    <b-row>
      <h3>{{ commodity.displayName }}</h3>
    </b-row>
    <b-row>
      <b-card no-body class="w-100">
        <b-tabs card>
          <b-tab title="Contracts" active class="w-100">
            <b-row>
              <template v-for="contract in commodity.contracts">
                <ContractDetailedCard
                  v-bind:key="contract"
                  v-bind:data="data.contractsData[commodity.name + '_' + contract]"
                />
              </template>
            </b-row>
          </b-tab>
          <ForecastTab v-bind:data="data.forecastGraphs[name]" />
        </b-tabs>
      </b-card>
    </b-row>
  </b-container>
</template>

<script>
import ContractDetailedCard from '@/components/ContractDetailedCard.vue'
import ForecastTab from '@/components/ForecastTab.vue'

export default {
  props: ['name', 'data'],
  components: {
    ContractDetailedCard,
    ForecastTab
  },
  computed: {
    commodity() {
      const name = this.name;
      return this.$store.state.commodities.find(function(c) {
        return c.name == name;
      });
    },
    aisData() {
      return this.$store.state.ais_data.map(x => x[this.name])
    },
  }
}
</script>
