import moment from 'moment'

export const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD'
})

export const colorsMixin = {
  data() {
    return {
      colors: {
        BLACK: '#071d33',
        LIGHT_GRAY: '#e3e8ec',
        GREEN: '#66c2a4',
        RED: '#e1575a',
        BLUE: '#218ae6',
        LIGHT_BLUE: '#aad3f3',
        CAT1: '#8dd3c7',
        CAT2: '#feffb2',
        CAT3: '#bebada',
        CAT4: '#fb8072',
        CAT5: '#7fb2d3',
        CAT6: '#fdb462',
        CAT7: '#b4de68',
        CAT8: '#fccde5',
        CAT9: '#d9d9d9',
        CAT10: '#bd7fbd',
        CAT11: '#ccebc5',
        CAT12: '#ffed6f'
      }
    }
  }
}

export const ppMixin = {
  methods: {
    pricingCriteria(contract, offset) {
      if (!offset) {
        offset = 0;
      }
      const gapLimit = {
        capesize: 0.075,
        panamax: 0.05
      }[contract.commodity];
      let upperBound = contract.marketPrice * (1 + gapLimit);
      let lowerBound = contract.marketPrice * (1 - gapLimit);
      const gap = Math.abs(contract.gap);
      if (gap <= gapLimit) {
        if (contract.recommendation == "B") {
          upperBound = contract.forecast*(1-gap)
        }
        if (contract.recommendation == "S") {
          lowerBound = contract.forecast*(1+gap)
        }
      }
      return Math.round(lowerBound+offset) + ' - ' + Math.round(upperBound+offset);
    },
    recommendationDisplay(contract) {
      const recommendations = {
        B: 'Buy',
        S: 'Sell',
        N: 'Do Nothing'
      }
      if (!contract.blocked || contract.blocked == "D") {
        return recommendations[contract.recommendation];
      }
      return 'Blocked (' + recommendations[contract.rawRecommendation] + ')';
    },
    ffaToOptionsRecommendation(rec) {
      if (rec == "B") return "Buy Call";
      if (rec == "S") return "Buy Put";
      return "Do Nothing";
    },
    optionsRecommendationDisplay(contract) {
      if (!contract.blocked || contract.blocked == "D") {
        return this.ffaToOptionsRecommendation(contract.recommendation);
      }
      return 'Blocked ('+ this.ffaToOptionsRecommendation(contract.rawRecommendation) + ')';
    },
    ppCommodity(commodity) {
      const commodities = {
        capesize: 'Capesize',
        panamax: 'Panamax',
        iron_ore: 'Iron Ore'
      };
      return commodities[commodity] || commodity;
    },
    ppBlocked(blocked) {
      if (blocked == 'D') {
        return 'Engine Disagreement'
      }
      return blocked
    },
    ppContract(contract) {
      return moment(contract, "MMMYY").format("MMMM YYYY");
    },
    ppTradeType(type) {
      const types = {
        call: 'Call',
        put: 'Put',
        ffa: 'FFA'
      };
      return types[type] || type
    },
    ppAction(action) {
      return {
        'buy': 'Buy',
        'sell': 'Sell'
      }[action] || action
    },
    ppFloat(f) {
      return Number(f).toFixed(2);
    },
    format(x) {
      return currencyFormatter.format(x).split('.')[0];
    }
  }
}

export const tableMixin = {
  methods: {
    itemKey(item) {
      return item.commodity + item.tennor + item.type
    },
    hideZeroFormat(x) {
      if (Math.abs(x) < 0.0001) return ''
      return this.format(x)
    }
  },
  computed: {
    collection() {
      return this.data.toCollection()
    },
    count() {
      return this.data.count()
    },
    sums() {
      const cols = this.data.listColumns()
      const sumColumns = [
        'premium_exposure', 'margin_exposure', 'pnl_trade',
        'net_lots', 'net_d', 'm2m', 'partially_realized'
      ].filter(c => cols.indexOf(c) >= 0)
      return sumColumns.reduce((acc, c) => {
        acc[c] = this.data.stat.sum(c)
        return acc
      }, {})
    },
    averages() {
      return ['strike', 'price'].reduce((acc, c) => {
        acc[c] = this.data.stat.mean(c)
        return acc
      }, {})
    },
  }
}
