import Vue from 'vue'
import Vuex from 'vuex'
import { taggedSum } from 'daggy'
import { v4 as uuidv4 } from 'uuid'
import DataFrame from 'dataframe-js'
import {
  buildDailyData,
  buildCommodities,
  buildPositionsData,
  getTenorForDateAndMonth
} from '@/domain'
import router from '@/router'

Vue.use(Vuex)

export const RemoteData = taggedSum('RemoteData', {
  NotAsked: [],
  Loading: [],
  Error: ['error'],
  Success: ['data']
})
RemoteData.prototype.map = function(f) {
  return this.cata({
    NotAsked: () => this,
    Loading: () => this,
    Error: () => this,
    Success: d => RemoteData.Success(f(d))
  })
}

export default new Vuex.Store({
  state: {
    uuid: uuidv4(),
    as_tenant: null,
    selected_context: null,
    data: RemoteData.NotAsked,
    daily_data: RemoteData.NotAsked,
    positions_data: RemoteData.NotAsked,
    oceanbolt_ais_data: RemoteData.NotAsked,
    commodities: [],
    equities: [],
    trading_data: {},
    user: null,
    current_date: null,
    connecting: true
  },
  mutations: {
    set_current_date(state, date) {
      state.current_date = date
    },
    set_data(state, data) {
      state.data = data
    },
    set_daily_data(state, data) {
      state.daily_data = data
    },
    set_positions_data(state, data) {
      state.positions_data = data
    },
    set_oceanbolt_ais_data(state, data) {
      state.oceanbolt_ais_data = data
    },
    set_trading_data(state, data) {
      state.trading_data = data
    },
    set_connecting(state, connecting) {
      state.connecting = connecting
    },
    set_user(state, user) {
      state.user = user
    },
    set_commodities(state, commodities) {
      state.commodities = commodities
    },
    set_equities(state, equities) {
      state.equities = equities
    },
    set_as_tenant(state, as_tenant) {
      state.as_tenant = as_tenant
    },
    set_selected_context(state, context) {
      state.selected_context = context
    }
  },
  actions: {
    async login(context) {
      const claims = await this._vm.$auth.getIdTokenClaims()
      const user = {
        name: claims.name,
        email: claims.email,
        picture: claims.picture,
        config: claims['https://truebearinginsights.com/config_v2']
      }
      context.commit('set_as_tenant', user.config.defaultTenant)
      context.commit(
        'set_selected_context',
        user.config.tenants[user.config.defaultTenant].defaultContext
      )
      context.commit('set_user', user)
      context.commit('set_data', RemoteData.Loading)
      context.dispatch('reload')
    },
    logout(context) {
      context.commit('set_user', null)
      context.commit('set_as_state', null)
    },
    reload(context) {
      if (this._vm.$auth.isAuthenticated) {
        context.dispatch('switchContext', context.state.selected_context)
      }
    },
    connected(context) {
      context.dispatch('reload')
      context.commit('set_connecting', false)
    },
    disconnected(context) {
      context.commit('set_connecting', true)
    },
    async new_trade(context, trade) {
      const token = await this._vm.$auth.getTokenSilently()
      const ctx = context.state.daily_data.cata({
        'NotAsked': () => null,
        'Loading': () => null,
        'Error': () => null,
        'Success': d => {
          const tradeEntry = d.lastDate
          const tenor = getTenorForDateAndMonth(d.tenor, tradeEntry, Number(trade.contract[1]))
          return {
            tradeEntry,
            tenor
          }
        }
      })
      if (ctx) {
        trade.trade_entry = ctx.tradeEntry
        trade.tennor = ctx.tenor.name
        trade.tennor_sdate = ctx.tenor.start
        trade.tennor_edate = ctx.tenor.end
        this._vm.$socket.client.emit(
          "new_trade",
          token,
          trade,
          context.state.selected_context,
          context.state.as_tenant
        )
      }
    },
    async confirm_position(context, trade) {
      const token = await this._vm.$auth.getTokenSilently()
      this._vm.$socket.client.emit(
        'confirm_position',
        token,
        trade,
        context.state.selected_context,
        context.state.as_tenant
      )
    },
    async cancel_position(context, trade) {
      const token = await this._vm.$auth.getTokenSilently()
      this._vm.$socket.client.emit(
        'cancel_position',
        token,
        trade.version,
        trade.id,
        trade.trade_ref,
        context.state.selected_context,
        context.state.as_tenant
      )
    },
    set_current_date(context, value) {
      context.commit('set_current_date', value)
      context.commit('set_daily_data', context.state.data.map(x => buildDailyData(value, x)))
      context.dispatch('load_date_data')
    },
    async load_date_data(context) {
      if (context.getters.currentMode != 'commodities') return
      const token = await this._vm.$auth.getTokenSilently()
      context.commit('set_positions_data', RemoteData.Loading)
      this._vm.$socket.client.emit(
        "positions_data_request",
        context.state.current_date,
        token,
        context.state.selected_context,
        context.state.as_tenant
      )
    },
    SOCKET_positions_data(context, data) {
      if (data) {
        context.commit('set_positions_data', RemoteData.Success(buildPositionsData(data)))
      } else {
        context.commit('set_positions_data', RemoteData.Loading)
      }
    },
    SOCKET_context_data(context, data) {
      //console.log('SOCKET_context_data', context, data)
      context.commit('set_commodities', buildCommodities(data))
      if (data) {
        context.commit('set_data', RemoteData.Success(data))
        context.commit(
          'set_daily_data',
          RemoteData.Success(
            buildDailyData(context.state.current_date, data)
          )
        )
      } else {
        //console.log('unsetting data')
        context.commit('set_data', RemoteData.Loading)
      }
    },
    SOCKET_dashboard_data(context, data) {
      context.commit('set_commodities', buildCommodities(data))
      if (data) {
        context.commit('set_data', RemoteData.Success(data))
        context.commit(
          'set_daily_data',
          RemoteData.Success(buildDailyData(context.state.current_date, data))
        )
      } else {
        context.commit('set_data', RemoteData.Loading)
      }
    },
    SOCKET_trading_data(context, data) {
      context.commit('set_trading_data', data)
    },
    SOCKET_oceanbolt_ais_data(context, data) {
      if (data) {
        context.commit('set_oceanbolt_ais_data', RemoteData.Success(data))
      } else {
        context.commit('set_oceanbolt_ais_data', RemoteData.Loading)
      }
    },
    async switchContext(context, dashboard_context) {
      console.log("Switching context to", dashboard_context)
      console.log("context", context)
      context.commit('set_selected_context', dashboard_context)
      const token = await this._vm.$auth.getTokenSilently()
      if (
        context.getters.currentContext && context.getters.currentContext.type == 'viterra-ais'
      ) {
        context.commit('set_data', RemoteData.NotAsked)
        context.commit('set_daily_data', RemoteData.NotAsked)
        context.commit('set_commodities', [])
        this._vm.$socket.client.emit(
          "oceanbolt_ais_data_request",
          token,
          context.getters.currentTenant
        )
      }
      else if  (
        context.getters.currentContext && context.getters.currentContext.type == 'sedna-ais'
      ) {
        context.commit('set_data', RemoteData.NotAsked)
        context.commit('set_daily_data', RemoteData.NotAsked)
        context.commit('set_commodities', [])
        this._vm.$socket.client.emit(
          "oceanbolt_ais_data_request",
          token,
          context.getters.currentTenant
        )
      }
      else {
        context.commit('set_data', RemoteData.Loading)
        context.commit('set_daily_data', RemoteData.Loading)
        context.commit('set_commodities', [])
        this._vm.$socket.client.emit(
          "oceanbolt_ais_data_request",
          token,
          context.getters.currentTenant
        )
        this._vm.$socket.client.emit(
          "context_subscribe",
          token,
          dashboard_context
        )
        context.dispatch('load_date_data')
      }
      if (router.currentRoute.path != '/') router.push('/')
    },
    async switchTenant(context, tenant) {
      context.commit('set_as_tenant', tenant)
      context.dispatch(
        'switchContext',
        context.getters.currentTenantConfig.defaultContext
      )
      if (router.currentRoute.path != '/') router.push('/')
    }
  },
  getters: {
    getDf(state) {
      return state.data.cata({
        NotAsked: () => null,
        Loading: () => null,
        Error: () => [],
        Success: d => (
          new DataFrame(d.dashboard.data, d.dashboard.columns)
        )
      })
    },
    tradeRefs(state) {
      return state.positions_data.cata({
        NotAsked: () => [],
        Loading: () => [],
        Error: () => [],
        Success: positions => {
          const ot = positions.openTrades
          const columns = ot.listColumns();
          const tradeEntryIx = columns.indexOf('trade_entry')
          const commodityIx = columns.indexOf('commodity')
          const tennorIx = columns.indexOf('tennor')
          const actionIx = columns.indexOf('action')
          const typeIx = columns.indexOf('type')
          const netLotsIx = columns.indexOf('net_lots')
          return ot.toArray().map(t => (
            [
              t[tradeEntryIx].replace(/-/g, ''),
              t[commodityIx],
              t[tennorIx],
              t[actionIx],
              t[typeIx],
              String(Math.abs(t[netLotsIx]))
            ].join('_')
          ))
        }
      })
    },
    potentialTrades(state) {
      return state.data.cata({
        NotAsked: () => [],
        Loading: () => [],
        Error: () => [],
        Success: data => {
          const row = data.dashboard.data.slice(-1)[0].reduce((acc, cell, ix) => {
            acc[data.dashboard.columns[ix]] = cell
            return acc
          }, {})

          const trades = []
          for (let commodity of state.commodities) {
            for (let contract of commodity.contracts) {
              const recommendation = row[commodity.name + '_Recommendation_' + contract]
              const FFAPrice = row[commodity.name + '_FFA_price_' + contract]
              const premium = row[commodity.name + '_U2_Premium_' + contract]
              const optionPrice = Math.round(Math.abs(premium / 30.0))
              const FFAPaid = row[commodity.name + '_initial_margin_' + contract]
              const maintMargin = row[commodity.name + '_maint_margin_' + contract]
              if (recommendation != 'N') {
                const trade = {
                  id: commodity.name + recommendation + contract,
                  commodity: commodity.name,
                  contract,
                  lots: commodity.lots,
                  trigger_strike: null,
                  trigger_maint_margin: null
                }
                if (commodity.default_trade == 'ffa') {
                  trade['type'] = 'ffa'
                  trade['action'] = recommendation == 'S' ? 'sell' : 'buy'
                  trade['trigger_price'] = FFAPrice
                  trade['trigger_paid'] = FFAPaid * commodity.lots
                  trade['trigger_maint_margin'] = maintMargin * commodity.lots
                } else {
                  const type = recommendation == 'S' ? 'put' : 'call'
                  trade['type'] = type
                  trade['action'] = 'buy'
                  trade['trigger_strike'] = row[commodity.name + '_U2_Strike_price_' + type + '_' + contract],
                  trade['trigger_price'] = optionPrice
                  trade['trigger_paid'] = optionPrice * commodity.lots
                }
                trades.push(trade)
              }
            }
          }
          return trades
        }
      })
    },
    dailyData(state) {
      return state.data.map(d => buildDailyData(state.current_date, d))
    },
    userName(state) {
      if (!state.user) return ''
      return state.user.name || state.user.email
    },
    avatar(state) {
      if (!state.user) return ''
      return state.user.picture
    },
    context(state) {
      return state.data.cata({
        'NotAsked': () => null,
        'Loading': () => null,
        'Error': () => null,
        'Success': d  => d.context
      })
    },
    isTBI(state) {
      if (!state.user) return false
      return state.user.config.isAdmin /* && !state.as_tenant */;
    },
    permissions(state) {
      if (!state.user) return {}
      const tenant = state.user.config.defaultTenant
      let config = state.user.config.tenants[tenant]
      if (state.as_tenant) {
        config = state.user.config.tenants[state.as_tenant]
      }
      return config['permissions'] || {}
    },
    canViewAIS(state, getters) {
      return !!getters.permissions['canViewAIS']
    },
    canViewForecasts(state, getters) {
      return !!getters.permissions['canViewForecasts']
    },
    canViewFeatureAnalysis(/* state, getters */) {
      // disable feature analysis
      return false
    },
    canViewPositions(state, getters) {
      return !!getters.permissions['canViewPositions']
    },
    canViewEquities(state, /* getters */) {
      return state.user.email == 'thomas@truebearinginsights.com'
    },
    canInputData(state, getters) {
      return !!getters.permissions['canInputData']
    },
    isShadowPortfolio(state, getters) {
      return getters.context && getters.context.shadow
    },
    effectiveDate(state) {
      return state.daily_data.cata({
        NotAsked: () => null,
        Loading: () => null,
        Error: () => null,
        Success: d => d.date
      })
    },
    isLastDate(state) {
      return state.daily_data.cata({
        NotAsked: () => null,
        Loading: () => null,
        Error: () => null,
        Success: d => d.date == d.lastDate
      })
    },
    tradingTabVisible(state, getters) {
      return state.user.config.isAdmin && !getters.isShadowPortfolio && getters.isLastDate
    },
    canSwitchContext(state) {
      if (!state.user) return false
      return true
    },
    authorizedContexts(state) {
      if (!state.user) return {}
      return state.user.config.tenants[state.as_tenant].contexts;
    },
    commoditiesContexts(state, getters) {
      if (!state.user) return {}
      const contexts = getters.currentTenantConfig.contexts
      return Object.keys(contexts).reduce((acc, key) => {
        if (contexts[key].type == 'commodities') {
          acc[key] = contexts[key]
        }
        return acc
      }, {})
    },
    hasCommoditiesContexts(state, getters) {
      return Object.keys(getters.commoditiesContexts).length > 0
    },
    equitiesContexts(state, getters) {
      if (!state.user) return {}
      const contexts = getters.currentTenantConfig.contexts
      return Object.keys(contexts).reduce((acc, key) => {
        if (contexts[key].type == 'equities') {
          acc[key] = contexts[key]
        }
        return acc
      }, {})
    },
    hasEquitiesContexts(state, getters) {
      return Object.keys(getters.equitiesContexts).length > 0
    },
    routesContexts(state, getters) {
      if (!state.user) return {}
      const contexts = getters.currentTenantConfig.contexts
      return Object.keys(contexts).reduce((acc, key) => {
        if (contexts[key].type == 'routes') {
          acc[key] = contexts[key]
        }
        return acc
      }, {})
    },
    hasRoutesContexts(state, getters) {
      return Object.keys(getters.routesContexts).length > 0
    },
    viterraContexts(state, getters) {
      if (!state.user) return {}
      const contexts = getters.currentTenantConfig.contexts
      return Object.keys(contexts).reduce((acc, key) => {
        if (contexts[key].type == 'viterra') {
          acc[key] = contexts[key]
        }
        return acc
      }, {})
    },
    hasViterraContexts(state, getters) {
      return Object.keys(getters.viterraContexts).length > 0
    },
    viterraAISContexts(state, getters) {
      if (!state.user) return {}
      const contexts = getters.currentTenantConfig.contexts
      return Object.keys(contexts).reduce((acc, key) => {
        if (contexts[key].type == 'viterra-ais') {
          acc[key] = contexts[key]
        }
        return acc
      }, {})
    },
    hasViterraAISContexts(state, getters) {
      return Object.keys(getters.viterraAISContexts).length > 0
    },    
    sednaAISContexts(state, getters) {
      if (!state.user) return {}
      const contexts = getters.currentTenantConfig.contexts
      return Object.keys(contexts).reduce((acc, key) => {
        if (contexts[key].type == 'sedna-ais')  {
          acc[key] = contexts[key]
        }
        return acc
      }, {})
    },
    hasSednaAISContexts(state, getters) {
      return Object.keys(getters.sednaAISContexts).length > 0
    },    

    bamContexts(state, getters) {
      if (!state.user) return {}
      const contexts = getters.currentTenantConfig.contexts
      return Object.keys(contexts).reduce((acc, key) => {
        if (contexts[key].type == 'bam') {
          acc[key] = contexts[key]
        }
        return acc
      }, {})
    },
    hasBAMContexts(state, getters) {
      return Object.keys(getters.bamContexts).length > 0
    },
    currentContext(state, getters) {
      if (!state.user) return null
      return getters.currentTenantConfig.contexts[
        state.selected_context || getters.currentTenantConfig.defaultContext
      ]
    },
    showIfchorLogo(state, getters) {
      if (!getters.currentContext) return false;
      return getters.currentContext.display == 'C10 and C14 forecasts'
    },
    showTBILogo(state, getters) {
      if (!getters.currentMode) return true;
      return getters.currentMode != 'bam'
    },
    currentMode(state, getters) {
      if (!state.user) return null
      return getters.currentContext.type
    },
    canSwitchTenant(state, getters) {
      if (!state.user) return false
      return !!getters.defaultTenantConfig.permissions['canSwitchTenant']
    },
    authorizedTenants(state) {
      if (!state.user) return {}
      return state.user.config.tenants
    },
    currentTenant(state) {
      if (!state.user) return null
      if (state.as_tenant) { return state.as_tenant }
      return state.user.config.defaultTenant
    },
    currentTenantConfig(state, getters) {
      if (!state.user) return {}
      return state.user.config.tenants[getters.currentTenant]
    },
    defaultTenantConfig(state) {
      if (!state.user) return {}
      return state.user.config.tenants[state.user.config.defaultTenant]
    },
    tradingContext(state, getters) {
      if (!state.user.config.isAdmin) {
        return null
      }
      return state.trading_data[
        state.selected_context || getters.currentTenantConfig.defaultContext
      ]
    },
    currentTenantDisplayName(state, getters) {
      return state.user.config.tenants[getters.currentTenant].display
    }
  },
  modules: {
  }
})
