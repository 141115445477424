<template>
  <div v-if="$store.getters.currentMode == 'commodities'">
    <CommoditiesDashboard v-bind:data="data" v-bind:commodities="commodities" />
  </div>
  <div v-else-if="$store.getters.currentMode == 'equities'">
    <EquitiesDashboard v-bind:data="data" />
  </div>
  <div v-else-if="$store.getters.currentMode == 'routes'">
    <RoutesDashboard v-bind:data="data" />
  </div>
  <div v-else-if="$store.getters.currentMode == 'viterra'">
    <ViterraDashboard v-bind:data="data" />
  </div>
  <div v-else-if="$store.getters.currentMode == 'viterra-ais'">
    <!-- <OceanboltAIS v-bind:data="data" /> -->
    <ViterraDashboard v-bind:data="data" />
  </div>
  <div v-else-if="$store.getters.currentMode == 'bam'">
    <BAMDashboard v-bind:data="data" />
  </div>
  <div v-else>
    <Error error="Unsupported portfolio type" />
  </div>
</template>

<script>
import CommoditiesDashboard from '@/components/CommoditiesDashboard.vue'
import EquitiesDashboard from '@/components/EquitiesDashboard.vue'
import RoutesDashboard from '@/components/RoutesDashboard.vue'
import ViterraDashboard from '@/components/ViterraDashboard.vue'
import BAMDashboard from '@/components/BAMDashboard.vue'
// import OceanboltAIS from '@/views/OceanboltAIS.vue'
import Error from '@/components/Error.vue'

export default {
  props: ['data', 'commodities'],
  components: {
    BAMDashboard,
    CommoditiesDashboard,
    EquitiesDashboard,
    RoutesDashboard,
    ViterraDashboard,
    // OceanboltAIS,
    Error
  }
}
</script>
