<template>
  <div>
    <b-container fluid>
      <b-row>
        <b-col sm="12">
          <h3>
            <b-form-radio-group
              class="pl-4"
              buttons
              v-model="forecastMode"
              button-variant="outline-primary"
              :options="[{text: 'Present Forecast', value: 'forecast'}, {text: 'Previous 3 Days Forecasts', value: 'progression'}]"
            >
            </b-form-radio-group>
          </h3>
        </b-col>
        <b-col
          v-for="forecast in orderedForecasts"
          v-bind:key="forecast.name"
        >
          <b-row>
            <b-col>
              <D3RouteForecast
                :ref="forecast.name"
                v-bind:data="forecast.d3ForecastData"
                :week="forecast.week"
                :route="forecast.label"
                range="static"
                spotLabel="Price"
                v-bind:mode="forecastMode"
              />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row class="mt-1 border-top">
        <b-col>
          <b-row>
            <b-col sm="12">
              <h5>
                Feature Importance
                <small>
                  <b-icon
                    id="fi_info"
                    icon="info-circle"
                  >
                  </b-icon>
                  <b-tooltip target="fi_info">
                    All categories contain both direct and derived features (inputs),
                    such as technical / statistical properties of
                    a price / time series in that category
                  </b-tooltip>
                </small>
              </h5>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              v-for="entry in featuresLegends"
              v-bind:key="entry.label"
              class="text-center"
            >
              <span>
                <big>
                  <big>
                    <span :style="`color: ${entry.color}`">●</span>
                  </big>
                </big>&nbsp;{{ entry.label }}
              </span>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              v-for="forecast in orderedForecasts"
              v-bind:key="forecast.name"
            >
              <D3RouteFeatures
                :ref="forecast.name"
                v-bind:data="forecast.d3FeaturesData"
                :week="forecast.week"
                :route="forecast.label"
              />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row class="mt-1 border-top">
        <b-col>
          <b-row>
            <b-col sm="12">
              <h5>
                Model Performance
                <small>
                  <b-icon
                    id="mp_info"
                    icon="info-circle"
                  >
                  </b-icon>
                  <b-tooltip target="mp_info">
                    Recent performance expressed in percentage terms relative to
                    model’s total historical performance
                  </b-tooltip>
                </small>
              </h5>
            </b-col>
            <b-col sm="3">
              <b-form-radio-group
                class="pl-4"
                buttons
                size="sm"
                v-model="errorMode"
                button-variant="outline-primary"
                :options="[{text: 'Relative Performance', value: 'relative'}, {text: 'Absolute Performance', value: 'absolute'}]"
              >
              </b-form-radio-group>
            </b-col>
            <b-col sm="9">
              <b-form-radio-group
                buttons
                size="sm"
                v-model="windowSize"
                button-variant="outline-primary"
                :options="[{text: 'Past Six Months', value: 'past_six_months'}, {text: 'Past Year', value: 'past_year'}]"
              >
              </b-form-radio-group>
            </b-col>
            <b-col
              v-for="entry in performanceLegends"
              v-bind:key="entry.label"
              class="text-center"
            >
              <span>
                <big>
                  <big><span :style="`color: ${entry.color}`">●</span></big>
                </big>&nbsp;{{ entry.label }}
              </span>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              v-for="forecast in orderedForecasts"
              v-bind:key="forecast.name"
            >
              <b-row>
                <b-col>
                  <D3RoutePerformance
                    :ref="forecast.name"
                    v-bind:data="forecast.d3PerformanceData[errorMode][windowSize]"
                    :week="forecast.week"
                    :route="forecast.label"
                  />
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import D3RouteForecast from '@/components/D3RouteForecast.vue'
import D3RouteFeatures from '@/components/D3RouteFeatures.vue'
import D3RoutePerformance from '@/components/D3RoutePerformance.vue'
import { colorsMixin } from '@/mixins'

export default {
  props: ['slug', 'data'],
  data() {
    return {
      forecastMode: 'forecast',
      errorMode: 'relative',
      windowSize: 'past_six_months'
    }
  },
  components: {
    D3RouteForecast,
    D3RouteFeatures,
    D3RoutePerformance,
  },
  mixins: [ colorsMixin ],
  computed: {
    orderedForecasts() {
      if (Object.keys(this.data.forecasts).length == 0) return []
      const configs = {
        'p1a': [
          this.data.forecasts['P1A_82_proxy_W4'],
          this.data.forecasts['P1A_82_proxy_W5'],
          this.data.forecasts['P1A_82_proxy_W6'],
        ],
        'p2a': [
          this.data.forecasts['P2A_82_proxy_W4'],
          this.data.forecasts['P2A_82_proxy_W5'],
          this.data.forecasts['P2A_82_proxy_W6'],
        ],
        'p3a': [
          this.data.forecasts['P3A_82_proxy_W4'],
          this.data.forecasts['P3A_82_proxy_W5'],
          this.data.forecasts['P3A_82_proxy_W6'],
        ],
        'p6': [
          this.data.forecasts['P6_82_W4'],
          this.data.forecasts['P6_82_W5'],
          this.data.forecasts['P6_82_W6'],
        ],
        'panamax-monthly': [
          this.data.forecasts['pmx_M1_roll'],
          this.data.forecasts['pmx_M2_roll'],
          this.data.forecasts['pmx_M3_roll'],
        ],
        'panamax-quarterly': [
          this.data.forecasts['pmx_Q2'],
          this.data.forecasts['pmx_Q3'],
          this.data.forecasts['pmx_Q4'],
        ],
        'capesize-monthly': [
          this.data.forecasts['capes_M1_roll'],
          this.data.forecasts['capes_M2_roll'],
          this.data.forecasts['capes_M3_roll'],
        ],
        'capesize-quarterly': [
          this.data.forecasts['capes_Q2'],
          this.data.forecasts['capes_Q3'],
          this.data.forecasts['capes_Q4'],
        ]
      }
      return configs[this.slug] || []
    },
    featuresLegends() {
      return [
        {label: 'Target', color: this.colors.CAT1},
        {label: 'Baltic\xa0Indices', color: this.colors.CAT6},
        {label: 'Baltic\xa0Futures', color: this.colors.CAT2},
        {label: 'AIS', color: this.colors.CAT3},
        {label: 'Metals\xa0/\xa0Materials', color: this.colors.CAT4},
        {label: 'Energy', color: this.colors.CAT5},
        {label: 'Agriculture', color: this.colors.CAT7},
        {label: 'Global\xa0Macro', color: this.colors.CAT8},
      ]
    },
    performanceLegends() {
      return [
        {label: 'Directional\xa0Accuracy', color: this.colors.CAT3},
        {label: 'Forecast\xa0Accuracy', color: this.colors.CAT8},
        {label: 'Range\xa0Accuracy', color: this.colors.CAT5},
      ]
    }
  }
}
</script>
