<template>
  <b-container fluid>
    <b-row>
      <h3>AIS Positions</h3>
    </b-row>
    <b-row>
      <b-col>
        <h5>Vessel Types</h5>
        <b-form-group label="Supramax">
          <b-form-radio-group
            id="btn-supramax"
            v-model="supramax"
            :options="supramaxOptions"
            name="supramax-vessels"
            button-variant="outline-primary"
            size="sm"
            buttons
        ></b-form-radio-group>
        </b-form-group>
        <b-form-group label="Handysize">
          <b-form-radio-group
            id="btn-handysize"
            v-model="handysize"
            :options="handysizeOptions"
            name="handysize-vessels"
            button-variant="outline-primary"
            size="sm"
            buttons
        ></b-form-radio-group>
        </b-form-group>
        <h5>Condition</h5>
        <b-form-group label="">
          <b-form-checkbox-group
            id="btn-condition"
            v-model="conditions"
            :options="conditionOptions"
            name="conditions"
            button-variant="outline-primary"
            size="sm"
            buttons
        ></b-form-checkbox-group>
        </b-form-group>
        <h5>Plots</h5>
        <b-form-group label="">
          <b-form-checkbox-group
            id="btn-plots"
            v-model="plots"
            :options="plotOptions"
            name="plots"
            button-variant="outline-primary"
            size="sm"
            buttons
        ></b-form-checkbox-group>
        </b-form-group>
        <h5>Regions</h5>
        <div>
          <b-form-checkbox-group
            id="btn-region"
            v-model="regions"
            :options="regionOptions"
            name="regions"
            button-variant="outline-primary"
            size="sm"
            switches
        ></b-form-checkbox-group>
        </div>
      </b-col>
      <b-col sm="8">
          <Plotly
            v-bind:data="aisPlot"
            style="height: 800px;"
            v-bind:layout="aisPlotLayout"
            :displaylogo="false"
            :responsive="true"
            />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { Plotly } from '@pankgeorg/vue-plotly'
import DataFrame from 'dataframe-js';

function sumArrays(...arrays) {
    const n = arrays.reduce((max, xs) => Math.max(max, xs.length), 0);
    const result = Array.from({ length: n });
    return result.map((_, i) => arrays.map(xs => xs[i] || 0).reduce((sum, x) => sum + x, 0));
  }

export default {
  props: ['data'],
  components: {Plotly},
  computed: {
    aisPlot() {
      return this.plots.reduce((plots, plot, index) => {
        const plotData = this.data[plot]
        const df = new DataFrame(plotData.rows, plotData.columns)
        const dates = df.toArray('date')
        const segments = {
          'supramax_all': 'All Supramax',
          'handymax': 'Handymax',
          'supramax': 'Supramax',
          'ultramax': 'Ultramax',
          'handysize_all': 'All Handysize',
          'small_handysize': 'Small Handysize',
          'large_handysize': 'Large Handysize'
        }
        const conditions = {
          'ballast': 'Ballast',
          'laden': 'Laden',
          'port': 'Port',
          'all_statuses': null
        }
        const variables = {
          'vessel_count': 'Count',
          'avg_speed': 'Avg. Speed',
          'vessel_dwt': 'DWT',
          // 'vessel_dwt_zscore': 'Std. Score of DWT',
          // 'vessel_dwt_quarter_zscore': 'Std. Score of Seasonal DWT',
        }
        const regions = this.regionOptions.reduce((rs, r) => {
          rs[r.value] = r.text
          return rs
        }, {})
        const seriesName = (c) => {
          const [segment, status, region] = c.split(' ')
          const elements = []
          if (status != 'all_statuses') {
            elements.push(conditions[status])
          }
          elements.push(segments[segment])
          elements.push(variables[plot])
          if (region != 'all_regions') {
            elements.push('in')
            elements.push(regions[region])
          }
          return elements.join(' ')
        }
        const selectedColumn = (c) => {
          if (c == 'date') return false;
          const [segment, status, region] = c.split(' ')
          if (segment != this.supramax && segment != this.handysize) return false
          if (this.conditions.indexOf(status) < 0) return false
          if (this.regions.indexOf(region) < 0) return false
          return true
        }
        const base = {
          type: "scatter",
          xaxis: 'x',
          yaxis: 'y' + (index + 1),
          x: dates
        }
        const newPlots = df.listColumns().reduce((acc, c) => {
          if (selectedColumn(c)) {
            acc.push(Object.assign({}, base, {
              y: df.toArray(c),
              name: seriesName(c)
            }))
          }
          return acc
        }, [])
        const newPlotsCount = Object.keys(newPlots).length
        console.log("plot", plot)
        if ((newPlotsCount > 1) && (plot == 'vessel_count' || plot == 'vessel_dwt')) {
          console.log(newPlotsCount, " items selected")
          var tempPlotsSum = newPlots[0]['y']
          for (var i = 1; i < newPlotsCount; i++) {
            tempPlotsSum = sumArrays(tempPlotsSum, newPlots[i]['y'])
          }
          const newPlotsSum = tempPlotsSum
          console.log(newPlotsSum)
          newPlots.push(Object.assign({}, base, {
            y: newPlotsSum,
            name: "Total".concat(" ", variables[plot])
          }))
          console.log("newPlots after push", newPlots)    
        }
        return plots.concat(newPlots)
      }, [])
    },
    aisPlotLayout() {
      let layout = {
        margin: {t: 30, b:30, l: 30, r: 0},
        showlegend: true,
        xaxis: {rangeslider: {}},
      }
      const increment = 1 / this.plots.length;
      layout = this.plots.reduce((l, p, i) => {
        l['yaxis'+(i+1)] = {
          domain: [increment*i, increment*(i+1)],
          anchor: 'y'+(i+1)
        }
        return l
      }, layout)
      return layout
    }
  },
  data: function() {
    return {
      'supramax': 'supramax_all',
      'supramaxOptions': [
        {text: 'All', value: 'supramax_all'},
        {text: 'Handymax', value: 'handymax'},
        {text: 'Supramax', value: 'supramax'},
        {text: 'Ultramax', value: 'ultramax'},
        {text: 'None', value: 'none'},
      ],
      'handysize': 'none',
      'handysizeOptions': [
        {text: 'All', value: 'handysize_all'},
        {text: 'Handysize Small', value: 'small_handysize'},
        {text: 'Handysize Large', value: 'large_handysize'},
        {text: 'None', value: 'none'},
      ],
      'conditions': ['laden'],
      'conditionOptions': [
        {text: 'Port', value: 'port'},
        {text: 'Ballast', value: 'ballast'},
        {text: 'Laden', value: 'laden'},
        {text: 'All', value: 'all_statuses'},
      ],
      'regions': [],
      'regionOptions': [
        {text: 'N. Pacific', value: 'n_pacific'},
        {text: 'S. Pacific', value: 's_pacific'},
        {text: 'NW Atlantic', value: 'nw_atlantic'},
        {text: 'SW Atlantic', value: 'sw_atlantic'},
        {text: 'NE Atlantic', value: 'ne_atlantic'},
        {text: 'SE Atlantic', value: 'se_atlantic'},
        {text: 'Baltic Sea', value: 'baltic_sea'},
        {text: 'W. Med', value: 'west_med'},
        {text: 'E. Med', value: 'east_med'},
        {text: 'N. Russia', value: 'n_russia'},
        {text: 'Gulf / India', value: 'gulf_india'},
        {text: 'Indian Ocean', value: 'indian_ocean'},
        {text: 'Asia Pacific', value: 'asia_pacific'},
        {text: 'SE Asia', value: 'se_asia'},
        {text: 'Australia', value: 'australia'},
        {text: 'All Regions', value: 'all_regions'},
      ],
      plots: [],
      plotOptions: [
        {text: 'Vessel Count', value: 'vessel_count'},
        {text: 'Avg. Speed', value: 'avg_speed'},
        {text: 'DWT', value: 'vessel_dwt'},
        // {text: 'Std. Score of DWT', value: 'vessel_dwt_zscore'},
        // {text: 'Std. Score of Seasonal DWT', value: 'vessel_dwt_quarter_zscore'},
      ]
    }
  }
}
</script>
