<template>
  <div>
    <b-container fluid>
      <b-row>
        <b-col sm="6">
          <h4>Executive Summary</h4>
          <p v-if="$store.getters.currentContext.display == 'C10 and C14'">
            <a href="https://truebearinginsights.com">True Bearing Insights (TBI)</a>
            and <a href="https://ifchor.com">IFCHOR</a> have partnered to provide
            market indicators for C10 and C14 Baltic routes, delivering their
            clients distinct strategic and commercial insights within the Capesize
            physical markets. TBI and IFCHOR have developed market indicators for
            each route one to two weeks forward, with models updating
            such assessments on a daily basis.
          </p>
          <p v-else-if="$store.getters.currentContext.display == 'Capesize'">
            <a href="https://truebearinginsights.com">True Bearing Insights (TBI)</a>
            and <a href="https://ifchor.com">IFCHOR</a> have partnered to provide
            market indicators for C10, C14 Baltic routes and the composite Capesize 
            5TC index, delivering their clients distinct strategic and commercial insights
            within the Capesize physical markets. TBI and IFCHOR have developed market indicators
            for each route and index one to two weeks forward, with models 
            updating such assessments on a daily basis.
          </p>
          <p v-else-if="$store.getters.currentContext.display == 'Panamax'">
            <a href="https://truebearinginsights.com">True Bearing Insights (TBI)</a>
            provides market indicators for P1A, P3A Baltic routes and the 
            composite Panamax 5TC index, delivering their clients distinct 
            strategic and commercial insights within the Panamax physical markets. 
            TBI has developed market indicators for each route and 
            index one to two weeks forward, with models updating such assessments 
            on a daily basis.
          </p>
          <p v-else-if="$store.getters.currentContext.display == 'Supramax'">
            <a href="https://truebearinginsights.com">True Bearing Insights (TBI)</a>
            provides market indicators for S2, S8 Baltic routes and the 
            composite Supramax 10TC index, delivering their clients distinct 
            strategic and commercial insights within the Panamax physical markets. 
            TBI has developed market indicators for each route and 
            index one to two weeks forward, with models updating such assessments 
            on a daily basis.
          </p>
          <p v-else-if="$store.getters.currentContext.display == 'C5TC and P5TC'">
            <a href="https://truebearinginsights.com">True Bearing Insights (TBI)</a>
            provides market indicators for the composite Capesize 
            5TC index and composite Panamax 5TC index, delivering their clients distinct 
            strategic and commercial insights within the Capesize and Panamax physical markets. 
            TBI has developed market indicators for each index one to two weeks forward, with models updating such assessments 
            on a daily basis.
          </p>          
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="6">
          <h4>Dashboard Overview</h4>
          <p>
            <span v-if="$store.getters.currentContext.display == 'C10 and C14'">
              TBI and IFCHOR have created an interactive dashboard to display these
              market indicators, as well as additional data related to the indicator
              models themselves. 
            </span>
            <span v-else-if="$store.getters.currentContext.display == 'Capesize'">
              TBI and IFCHOR have created an interactive dashboard to display these
              market indicators, as well as additional data related to the indicator
              models themselves. 
            </span>
            <span v-else-if="$store.getters.currentContext.display == 'Panamax'">
              TBI has created an interactive dashboard to display these
              market indicators, as well as additional data related to the indicator
              models themselves. 
            </span>
            <span v-else-if="$store.getters.currentContext.display == 'Supramax'">
              TBI has created an interactive dashboard to display these
              indicators, as well as additional data related to the indicator
              models themselves. 
            </span>
            <span v-else-if="$store.getters.currentContext.display == 'C5TC and P5TC'">
              TBI has created an interactive dashboard to display these 
              indicators, as well as additional data related to the indicator
              models themselves. 
            </span>
            The dashboard consists of three main sections:<br><br>
            <ol>
              <li>
                <a href="#indicator"><big><b>Indicator Data</b></big></a> -
                displays data related to present and past indicators, updated daily
              </li>
              <li>
                <a href="#feature"><big><b>Feature Importance Data</b></big></a> -
                summarizes the underlying inputs driving the indicator
              </li>
              <li>
                <a href="#performance"><big><b>Model Performance Data</b></big></a> -
                details the indicator's recent performance
              </li>
            </ol>
            <img v-if="$store.getters.currentContext.display == 'C10 and C14'"
              src="@/assets/dashboard_display.png"
              alt="Dashboard Display"
              width="100%"
            >
            <img v-else-if="$store.getters.currentContext.display == 'Capesize'"
              src="@/assets/dashboard_display_capesize.png"
              alt="Dashboard Display"
              width="100%"
            >
            <img v-else-if="$store.getters.currentContext.display == 'C5TC and P5TC'"
              src="@/assets/dashboard_display_capesize.png"
              alt="Dashboard Display"
              width="100%"
            >            
            <img v-else-if="$store.getters.currentContext.display == 'Panamax'"
              src="@/assets/dashboard_display_panamax.png"
              alt="Dashboard Display"
              width="100%"
            >
            <img v-else-if="$store.getters.currentContext.display == 'Supramax'"
              src="@/assets/dashboard_display_supramax.png"
              alt="Dashboard Display"
              width="100%"
            >
            <br><br>
            The following provides a detailed overview of this dashboard, and
            the interpretations associated with its different elements.
          </p>
        </b-col>
      </b-row>
      <b-row id="indicator">
        <b-col sm="6">
          <h4>Indicator Data</h4>
          <p>
            The main section of the dashboard - Indicator Data - contains
            current and previous indicator data within the context of underlying
            market conditions. There are two main graphics, which are accessed
            via the button on the top left of the page of the dashboard:<br><br>
            <ul>
              <li><big><b>Present Indicator Dials</b></big></li>
              <li><big><b>Previous 3 Day Indicator Dials</b></big></li>
            </ul>
            <img v-if="$store.getters.currentContext.display == 'C10 and C14'"
              src="@/assets/forecast_data.png"
              alt="Indicator Data"
              width="100%"
            >
            <img v-else-if="$store.getters.currentContext.display == 'Capesize'"
              src="@/assets/forecast_data_capesize.png"
              alt="Indicator Data"
              width="100%"
            >
            <img v-else-if="$store.getters.currentContext.display == 'C5TC and P5TC'"
              src="@/assets/forecast_data_capesize.png"
              alt="Indicator Data"
              width="100%"
            >
            <img v-else-if="$store.getters.currentContext.display == 'Panamax'"
              src="@/assets/forecast_data_panamax.png"
              alt="Indicator Data"
              width="100%"
            >
            <img v-else-if="$store.getters.currentContext.display == 'Supramax'"
              src="@/assets/forecast_data_supramax.png"
              alt="Indicator Data"
              width="100%"
            >
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="3">
          The <big><em>Present Indicator</em></big> dial contains three main elements:
          <br><br>
          <ol>
            <li>
              <big><b>Indicator Ranges</b></big> - indicate the indicator's range of spot prices
              within the target week
              <ol type="a">
                <li>
                  50% Range = dark blue shade within exterior circle; numeric
                  range detailed in center of dial
                </li>
                <li>
                  70% Range = light blue shade within exterior circle
                </li>
                <li>
                  The indicator's range on average should capture 50, 70% of spot prices within the target week
                </li>
              </ol>
            </li>
            <br>
            <li>
              <big><b>Indicator Average and Direction</b></big> - indicate the average
              spot price of the target week and its direction from current
              spot price.
              <ol type="a">
                <li>
                  Average - green / red arrow between exterior and
                  interior circles
                </li>
                <li>
                  Direction - indicated by a) color of average arrow
                  (green = up, red = down), and b) arrow direction / color in
                  the center of dial
                </li>
              </ol>
            </li>
            <br>
            <li>
              <big><b>Current Spot Price</b></big>
              <ol type="a">
                <li>
                  Black line within the interior circle; numerically detailed
                  in center of dial
                </li>
              </ol>
            </li>
          </ol>
        </b-col>
        <b-col sm="3">
            <img 
              v-if="
              $store.getters.currentContext.display == 'C10 and C14'|| 
              $store.getters.currentContext.display == 'Capesize' ||
              $store.getters.currentContext.display == 'C5TC and P5TC'" 
              src="@/assets/forecast_w1.png"
              alt="Indicator W1"
              width="80%"
            >
            <img 
              v-if="
              $store.getters.currentContext.display == 'Panamax'|| 
              $store.getters.currentContext.display == 'Supramax'"
              src="@/assets/forecast_w1_no_label.png"
              alt="Indicator W1"
              width="80%"
            >
            <img 
              v-if="
              $store.getters.currentContext.display == 'C10 and C14'|| 
              $store.getters.currentContext.display == 'Capesize'||
              $store.getters.currentContext.display == 'C5TC and P5TC market indicators'"               
              src="@/assets/forecast_w2.png"
              alt="Indicator W2"
              width="80%"
            >
            <img 
              v-if="
              $store.getters.currentContext.display == 'Panamax'|| 
              $store.getters.currentContext.display == 'Supramax'"
              src="@/assets/forecast_w2_no_label.png"
              alt="Indicator W2"
              width="80%"
            >
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col sm="3">
          The <big><em>Previous 3 Day Indicator Dial</em></big> contains three main elements
          as well:
          <br><br>
          <ol>
            <li>
              <big><b>Current Indicator Range and Spot Price</b></big> - exterior circle
              <ol type="a">
                <li>
                  Current Indicator Range - dark, light blue shades (50, 70%)
                  within exterior circle
                </li>
                <li>
                  Current Spot Price - black line within exterior circle
                </li>
              </ol>
            </li>
            <br>
            <li>
              <big><b>Previous Indicator Ranges and Spot Prices</b></big> - three interior circles
              corresponding to previous three days
              <ol type="a">
                <li>
                  Ordered from most recent (outside) to least recent (inside)
                </li>
                <li>
                  Previous Indicator Ranges - dark, light blue shades (50, 70%)
                  within interior circles
                </li>
                <li>
                  Previous Spot Prices - black line within interior circles
                </li>
              </ol>
            </li>
            <br>
            <li>
              <big><b>Indicator Trend</b></big> - directional change in current indicator relative
              to three days prior
              <ol type="a">
                <li>
                  Directional change is measured on the basis of the indicated
                  average spot price; green = indicator trending up,
                  red = indicator trending down
                </li>
              </ol>
            </li>
          </ol>
        </b-col>
        <b-col sm="3">
            <img
              v-if="
              $store.getters.currentContext.display == 'C10 and C14'|| 
              $store.getters.currentContext.display == 'Capesize'||
              $store.getters.currentContext.display == 'C5TC and P5TC'"            
              src="@/assets/trend_w1.png"
              alt="Indicator Trend W1"
              width="85%"
            >
            <img
              v-if="
              $store.getters.currentContext.display == 'Panamax'|| 
              $store.getters.currentContext.display == 'Supramax'"            
              src="@/assets/trend_w1_no_label.png"
              alt="Indicator Trend W1"
              width="85%"
            >
            <img
              v-if="
              $store.getters.currentContext.display == 'C10 and C14'|| 
              $store.getters.currentContext.display == 'Capesize'||
              $store.getters.currentContext.display == 'C5TC and P5TC'"            
              src="@/assets/trend_w2.png"
              alt="Indicator Trend W2"
              width="85%"
            >
            <img
              v-if="
              $store.getters.currentContext.display == 'Panamax'|| 
              $store.getters.currentContext.display == 'Supramax'"            
              src="@/assets/trend_w2_no_label.png"
              alt="Indicator Trend W2"
              width="85%"
            >
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col sm="6">
          <p>
            Users can access additional information regarding an indicator’s
            historical behavior via the dashboard’s Date Tool. By default,
            the dashboard will always display the most recent indicators
            available, the date of which is found in the upper right header.
            Users have the ability to change the display settings to any date
            in the past (up to 2017-01-01), however, which will update the
            dashboard to reflect the data on that date. To do this, users can
            click on the "Setting" icon next to the present date, which will
            expand a calendar selection tool as shown below:
          </p>
          <img v-if="$store.getters.currentContext.display == 'C10 and C14'"
            src="@/assets/calendar.png"
            alt="Date Pickup"
            width="100%"
          >
          <img v-else-if="$store.getters.currentContext.display == 'Capesize'"
            src="@/assets/calendar_capesize.png"
            alt="Date Pickup"
            width="100%"
          >
          <img v-else-if="$store.getters.currentContext.display == 'C5TC and P5TC'"
            src="@/assets/calendar_capesize.png"
            alt="Date Pickup"
            width="100%"
          >
          <img v-else-if="$store.getters.currentContext.display == 'Panamax'"
            src="@/assets/calendar_panamax.png"
            alt="Date Pickup"
            width="100%"
          >
          <img v-else-if="$store.getters.currentContext.display == 'Supramax'"
            src="@/assets/calendar_supramax.png"
            alt="Date Pickup"
            width="100%"
          >
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="3">
          <br>
          <p>
            By selecting a date in the past the <em>Present Indicator</em> dials will
            also contain another element - the actual realized spot prices
            relative to the historical indicators. The orange dots and line
            indicate the realized spot prices and their average, respectively.
            The numeric average of spot prices is also detailed in the center
            of the dial
          </p>
          <p>
            This is a visual tool to inspect behavior at discrete points in
            time, but it is important to remember that any individual day is
            one sample out of thousands, and model performance should be
            assessed over the long run in order to draw appropriate conclusions.
          </p>
        </b-col>
        <b-col sm="3">
          <img
            v-if="
            $store.getters.currentContext.display == 'C10 and C14'||
            $store.getters.currentContext.display == 'Capesize'||
            $store.getters.currentContext.display == 'C5TC and P5TC'"
            src="@/assets/spot_w2.png"
            alt="Spot Prices"
            width="90%"
          >
          <img
            v-else-if="
            $store.getters.currentContext.display == 'Panamax'||
            $store.getters.currentContext.display == 'Supramax'"
            src="@/assets/spot_w2_no_label.png"
            alt="Spot Prices"
            width="90%"
          >
        </b-col>
      </b-row>
      <b-row id="feature">
        <b-col sm="6">
          <h4>Feature Importance Data</h4>
          <p>
            The second section of the dashboard - Feature Importance Data -
            contains donut charts (one per target) highlighting the
            distribution of high-level feature categories that are most
            relevant in the indicator over time.
          </p>
          <p>
            The feature categories consist of:
            <br><br>
            <ol>
              <li v-if="$store.getters.currentContext.display == 'C10 and C14'">
                <big><b>Target</b></big> - technical / statistical properties related to the
                target's (e.g. C10, C14) price history
              </li>
              <li v-else-if="$store.getters.currentContext.display == 'Capesize'">
                <big><b>Target</b></big> - technical / statistical properties related to the
                target's (e.g. C10, C14, C5TC) price history
              </li>
              <li v-else-if="$store.getters.currentContext.display == 'C5TC and P5TC'">
                <big><b>Target</b></big> - technical / statistical properties related to the
                target's (e.g. C5TC, P5TC) price history
              </li>
              <li v-else-if="$store.getters.currentContext.display == 'Panamax'">
                <big><b>Target</b></big> - technical / statistical properties related to the
                target's (e.g. P1A, P3A, P5TC) price history
              </li>
              <li v-else-if="$store.getters.currentContext.display == 'Supramax'">
                <big><b>Target</b></big> - technical / statistical properties related to the
                target's (e.g. S2, S8, S10TC) price history
              </li>
              <li>
                <big><b>Baltic Indices</b></big> - various composite / individual route prices
                across different segment vessels (Capesize, Panamax, etc)
              </li>
              <li>
                <big><b>Baltic Futures</b></big> - various composite / individual route futures
                prices across different vessel segments and time horizons
              </li>
              <li>
                <big><b>Commodity Futures</b></big> - raw materials, metals, energy, agriculture
                and additional commodity futures across time horizons
              </li>
              <li>
                <big><b>Other</b></big> - engineered / aggregated vessel data
              </li>
              <li>
                <big><b>Equities / ETFs</b></big> - equities and ETFs in related
                upstream industries
              </li>
            </ol>
          </p>
          <p v-if="$store.getters.currentContext.display == 'C10 and C14'">
            Below is an example of feature importance pie charts for C10 weeks one and two:
          </p>
          <p v-else-if="$store.getters.currentContext.display == 'Capesize'">
            Below is an example of feature importance pie charts for weeks one and two:
          </p>
          <p v-else-if="$store.getters.currentContext.display == 'C5TC and P5TC'">
            Below is an example of feature importance pie charts for weeks one and two:
          </p>
          <p v-else-if="$store.getters.currentContext.display == 'Panamax'">
            Below is an example of feature importance pie charts for weeks one and two:
          </p>
          <p v-else-if="$store.getters.currentContext.display == 'Supramax'">
            Below is an example of feature importance pie charts for weeks one and two:
          </p>
          <img v-if="$store.getters.currentContext.display == 'C10 and C14'"
            src="@/assets/feature_importance.png"
            alt="Feature Importance"
            width="100%"
          >
          <img v-else-if="$store.getters.currentContext.display == 'Capesize'"
            src="@/assets/feature_importance_capesize.png"
            alt="Feature Importance"
            width="100%"
          >
          <img v-else-if="$store.getters.currentContext.display == 'C5TC and P5TC'"
            src="@/assets/feature_importance_capesize.png"
            alt="Feature Importance"
            width="100%"
          >
          <img v-else-if="$store.getters.currentContext.display == 'Panamax'"
            src="@/assets/feature_importance_panamax.png"
            alt="Feature Importance"
            width="100%"
          >
          <img v-else-if="$store.getters.currentContext.display == 'Supramax'"
            src="@/assets/feature_importance_supramax.png"
            alt="Feature Importance"
            width="100%"
          >
          <p>
            It is important to note that within each category, hundreds of
            features are represented, including not only the raw feature
            itself (e.g. C10 spot price) but various transformations of the
            raw feature as well (e.g. technical analyses (moving average
            spreads), statistical analyses (decompositions), etc).
          </p>
          <p>
            Additionally, not all features in a category will affect the
            prediction in the same direction, and in many cases features
            within a category will have different coefficient signs
            (i.e. negative vs positive correlations). Therefore, it should
            not be interpreted, for example, that if a model that relies
            heavily on baltic futures this means that baltic futures and
            indicators are heavily correlated in one direction - this is
            not the case.
          </p>
          <p>
            A more appropriate interpretation of the feature importance chart,
            for example, is to use it to assess market conditions that may be
            outside the predictive model's scope. For instance, if there is
            an event unfolding in the market that falls within the categories
            defined, the indicators should be met with more assurance as the
            model has likely experienced / accounted for such relationships
            in the past, whereas events that happen outside the categories
            that are defined should be met with more caution.
          </p>
        </b-col>
      </b-row>
      <b-row id="performance">
        <b-col sm="6">
          <h4>Model Performance Data</h4>
          <p>
            The third section of the dashboard - Model Performance Data -
            contains three measures that assess different aspects of the
            predictive model's performance; these include:
            <br><br>
            <ol>
              <li>
                <big><b>Directional Accuracy</b></big> - represents whether the model correctly
                indicates the average spot price per week up / down relative
                to average price of current week (i.e. is it getting direction
                right?)
              </li>
              <li>
                <big><b>Indicator Accuracy</b></big> - represents the error between the indicated
                average spot price per week versus the actual average spot price
                per week (i.e. is the indicator error low?)
              </li>
              <li>
                <big><b>Range Accuracy</b></big> - represents the alignment between the range
                targets (50 and 70%), and the proportion of actual individual
                spot prices that fell within the predicted range(s) for the
                target week (i.e. is the indicated range appropriate?)
              </li>
            </ol>
          </p>
          <p>
            The performance measures are expressed in two variations -
            relative and absolute - and rely on recent performance windows
            of either six or twelve months.
          </p>
          <p>
            The relative performance measures represent the percentile rank
            of the model’s recent performance relative to its total historical
            performance (2018-01 to present). In this way, we are making the
            interpretation of each metric standardized across targets and weeks
            as follows:
            <br><br>
            <ul>
              <li>
                <b>0%</b> = model is currently performing at its worst level ever
                regarding the metric in question
              </li>
              <li>
                <b>50%</b> = model is currently performing at its average level
                regarding the metric in question
              </li>
              <li>
                <b>100%</b> = model is currently performing at its best level ever
                regarding the metric in question
              </li>
            </ul>
          </p>
          <p>
            The relative performance dials identify (in red) when a metric is
            below 20% (i.e. model is currently performing at the lower end of
            its usual performance), and the following "rule of thumb"
            interpretations should be used to assess comprehensive model
            performance:
            <br><br>
            <ul>
              <li>
                <b>All metrics above 20%</b> = model behaving as expected
              </li>
              <li>
                <b>1 metric below 20%</b> = model may be starting to exhibit lower
                performance, but still valid
              </li>
              <li>
                <b>2 metrics below 20%</b> = model is starting to exhibit lower
                performance, use more caution in model outputs
              </li>
              <li>
                <b>3 metrics below 20%</b> = model is exhibiting lower performance
                than usual, worth a deeper investigation into model behavior,
                use caution
              </li>
            </ul>
          </p>
          <p>
            The absolute performance measures represent the nominal value of
            the various metrics in order to assist in more direct
            interpretation and relate relative performance figures towards.
            In this view of model performance, the interpretation of each
            metric is as follows:
            <br><br>
            <ul>
              <li>
                <b>Directional Accuracy</b> = average directional accuracy
              </li>
              <li>
                <b>Indicator Accuracy</b> = 1 - median absolute percentage error (MedAPE)
              </li>
              <li>
                <b>Range Accuracy</b> = 1 -  abs(average difference between the
                target confidence interval (i.e. 50%) and percentage of actual
                values captured)
              </li>
            </ul>
          </p>
          <p>
            Therefore, similar to relative performance metrics, the absolute
            metrics have been structured so that the greater the nominal
            value the better the model performance.
          </p>
          <p>
            Below is an example of how the performance dials may be represented
            in the dashboard. Each circle represents a performance metric
            corresponding to the legend on top. The largest number within the
            circle is an average of the three performance metrics, with each
            individual performance metric listed below.
          </p>
          <img v-if="$store.getters.currentContext.display == 'C10 and C14'"
            src="@/assets/model_performance.png"
            alt="Model Performance"
            width="100%"
          >
          <img v-else-if="$store.getters.currentContext.display == 'Capesize'"
            src="@/assets/model_performance_capesize.png"
            alt="Model Performance"
            width="100%"
          >
          <img v-else-if="$store.getters.currentContext.display == 'C5TC and P5TC'"
            src="@/assets/model_performance_capesize.png"
            alt="Model Performance"
            width="100%"
          >
          <img v-else-if="$store.getters.currentContext.display == 'Panamax'"
            src="@/assets/model_performance_panamax.png"
            alt="Model Performance"
            width="100%"
          >
          <img v-else-if="$store.getters.currentContext.display == 'Supramax'"
            src="@/assets/model_performance_supramax.png"
            alt="Model Performance"
            width="100%"
          >
        </b-col>
      </b-row>
      <b-row id="review">
        <b-col sm="6">
          <h4>Review</h4>
          <p v-if="$store.getters.currentContext.display == 'C10 and C14'">
            The dashboard contains information related to the indicators
            for routes C14 and C10 one to two weeks forward.
          </p>
          <p v-else-if="$store.getters.currentContext.display == 'Capesize'">
            The dashboard contains information related to the indicators
            for routes C14, C10 and the Capesize composite index one to two weeks forward.
          </p>
          <p v-else-if="$store.getters.currentContext.display == 'C5TC and P5TC market indicators'">
            The dashboard contains information related to the indicators for the Capesize composite 
            index and Panamax composite index one to two weeks forward.
          </p>
          <p v-else-if="$store.getters.currentContext.display == 'Panamax'">
            The dashboard contains information related to the indicators
            for routes P1A, P3A and the Panamax composite index one to two weeks forward.
          </p>
          <p v-else-if="$store.getters.currentContext.display == 'Supramax'">
            The dashboard contains information related to the indicators
            for routes S2, S8, and the Supramax composite index one to two weeks forward.
          </p>
          <p>
            The main commercial element of the dashboard is the indicator visual
            itself, however, this is supported with contextual model
            information related to the model's underlying features and its
            recent performance.
          </p>
          <p>
            The dashboard is most useful when employed consistently as an input
            into decision processes with the understanding that while short
            term performance may fluctuate, the models deliver consistent
            forward-looking commercial insights / advantages over the long
            run - selective use of the dashboard may reduce / eliminate
            such advantages.
          </p>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'Documentation',
}
</script>
