<template>
  <b-container fluid>
    <b-row>
      <h3>Positions</h3>
    </b-row>
    <b-row v-if="$store.getters.canViewPositions">
      <b-card no-body class="w-100">
        <b-tabs card>
          <b-tab title="Overview" active class="w-100">
            <OpenSummaryTable v-bind:data="data.openSummary" />
            <RealizedSummaryTable v-bind:data="data.realizedSummary" />
          </b-tab>
          <b-tab title="Open" class="w-100">
            <OpenTradesTable v-bind:data="data.openTrades" />
          </b-tab>
          <b-tab title="Realized" class="w-100">
            <RealizedTradesTable v-bind:data="data.realizedTrades" />
          </b-tab>
          <b-tab title="Trading" class="w-100" v-if="$store.getters.tradingTabVisible">
            <!--
            <h5>Limits Overview</h5>
            <b-table :items="limitsItems" :fields="limitsFields" small hover>
            </b-table>
            -->
            <h5>New Position</h5>
            <NewPositionForm />
            <h5 class="mt-3">Pending Positions</h5>
            <PendingPositionsTable />
            <h5 class="mt-3">Recently Entered Positions</h5>
            <RecentPositionsTable />
          </b-tab>
        </b-tabs>
      </b-card>
    </b-row>
  </b-container>
</template>

<script>
import OpenSummaryTable from '@/components/OpenSummaryTable.vue'
import RealizedSummaryTable from '@/components/RealizedSummaryTable.vue'
import OpenTradesTable from '@/components/OpenTradesTable.vue'
import RealizedTradesTable from '@/components/RealizedTradesTable.vue'
import NewPositionForm from '@/components/NewPositionForm.vue'
import PendingPositionsTable from '@/components/PendingPositionsTable.vue'
import RecentPositionsTable from '@/components/RecentPositionsTable.vue'
import { ppMixin } from '@/mixins'

export default {
  props: ['data'],
  mixins: [ppMixin],
  components: {
    OpenSummaryTable,
    RealizedSummaryTable,
    OpenTradesTable,
    RealizedTradesTable,
    NewPositionForm,
    PendingPositionsTable,
    RecentPositionsTable
  },
  data: function() {
    return {
      limitsFiels: [
        {key: 'name', label: 'Limit', sortable: true},
        {key: 'current', label: 'Current Level', sortable: false},
        {key: 'max', label: 'Max Level', sortable: false},
        {key: 'diff', label: 'Available Difference', sortable: false},
        {key: 'withinLimit', label: 'Within Limit', sortable: false}
      ]
    }
  },
  computed: {
    exitCount() {
      return 0
    },
    limitsItems() {
      const self = this;
      return [
        {
          'name': 'VaR',
          'current': self.format(200000),
          'max': self.format(300000),
          'diff': self.format(100000),
          'withinLimit': true,
        },
        {
          'name': 'Premium/Margin Exposure',
          'current': '25%',
          'max': '50%',
          'diff': '25%',
          'withinLimit': true,
        },
        {

        }
      ];
    }
  }
}
</script>
