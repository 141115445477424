<template>
  <div>
    <b-container fluid>
      <b-row>
        <b-col sm="6">
          <h4>Dashboard Overview</h4>
          <p>
            TBI has developed predictive models to forecast key physical routes
            and FFA markets in order to assist trading
            operations and strategies. TBI has also created a new interactive
            dashboard to display such forecast data, as well as additional data
            related to the predictive models themselves.<br><br>
            The dashboard consists of three main sections that are supported by
            four main graphical elements. The three sections include:
            <ol>
              <li>
                <big><b>Dashboard <small>(Homepage)</small></b></big> -
                displays data related to present forecasts for all routes and
                FFA markets
              </li>
              <li>
                <big><b>AIS</b></big> -
                displays time series data related to AIS features for both
                Panamax and Capesize vessels
              </li>
              <li>
                <big><b>Forecast Pages <small>(8 in total, e.g. P1A W4-6)</small></b></big> -
                displays data per market related to present and past forecasts,
                summarizes underlying drivers of forecasts, and details the
                predictive model's recent performance
              </li>
            </ol>
            <br>
            <img
              src="@/assets/viterra_dashboard.png"
              alt="Dashboard (Homepage)"
              width="100%"
            >
            <br><br>
            <img
              src="@/assets/viterra_ais.png"
              alt="AIS"
              width="100%"
            >
            <br><br>
            <img
              src="@/assets/viterra_forecast.png"
              alt="Forecast Page"
              width="100%"
            >
            <br><br><br>
            These three sections are supported by the following four graphical elements:
            <br><br>
            <ol>
              <li>
                <b>Forecast Dials</b>
                - displays data related to present and past forecasts
              </li>
              <li>
                <b>Feature Importance Charts</b>
                - summarizes the underlying inputs driving the forecast
              </li>
              <li>
                <b>Model Performance Dials</b>
                - details the predictive model's recent performance
              </li>
              <li>
                <b>AIS Charts</b>
                - time series data related to AIS features, including
                standardized and seasonal features
              </li>
            </ol>
            <br>
            The following provides a detailed overview of the dashboard, and
            the interpretations associated with its different elements.
          </p>
          <h4>Forecast Dials</h4>
          <p>
            The main element of the dashboard - Forecast Dials - contain
            current and previous forecast data within the context of
            underlying market conditions. There are two main graphics (only
            one on Dashboard Homepage), which are accessed via the button on
            the top left of the Forecast Page:
            <br><br>
            <ul>
              <li><big><em>Present Forecast Dials</em></big></li>
              <li><big><em>Previous 3 Day Forecast Dials</em></big></li>
            </ul>
            <img
              src="@/assets/viterra_forecast_data.png"
              alt="Forecast Data"
              width="100%"
            >
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="3">
          The <big><em>Present Forecast</em></big> dial contains three main elements:
          <br><br>
          <ol>
            <li>
              <big><b>Forecast Ranges</b></big> - indicate the forecasted range of  prices
              within the target week
              <ol type="a">
                <li>
                  50% Range = dark blue shade within exterior circle; numeric
                  range detailed in center of dial
                </li>
                <li>
                  70% Range = light blue shade within exterior circle
                </li>
                <li>
                  The forecasted range on average should capture 50, 70% of prices within the target week
                </li>
              </ol>
            </li>
            <br>
            <li>
              <big><b>Forecast Average and Direction</b></big> - indicate the forecasted average
              price of the target week and its direction from current price.
              <ol type="a">
                <li>
                  Forecast Average - green / red arrow between exterior and
                  interior circles
                </li>
                <li>
                  Direction - indicated by a) color of average forecast arrow
                  (green = up, red = down), and b) arrow direction / color in
                  the center of dial
                </li>
              </ol>
            </li>
            <br>
            <li>
              <big><b>Current Price</b></big>
              <ol type="a">
                <li>
                  Black line within the interior circle; numerically detailed
                  in center of dial
                </li>
                <li>
                  Represents trailing average weekly index / FFA roll value
                </li>
              </ol>
            </li>
          </ol>
        </b-col>
        <b-col sm="3">
            <img
              src="@/assets/viterra_forecast_detail_1.png"
              alt="Forecast Detail 1"
              width="80%"
            >
            <img
              src="@/assets/viterra_forecast_detail_2.png"
              alt="Forecast Detail 2"
              width="80%"
            >
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col sm="3">
          The <big><em>Previous 3 Day Forecast Dial</em></big> contains three main elements
          as well:
          <br><br>
          <ol>
            <li>
              <big><b>Current Forecast Range and Cuurent Price</b></big> - exterior circle
              <ol type="a">
                <li>
                  Current Forecast Range - dark, light blue shades (50, 70%)
                  within exterior circle
                </li>
                <li>
                  Current Price - black line within exterior circle
                </li>
              </ol>
            </li>
            <br>
            <li>
              <big><b>Previous Forecast Ranges and Previous Prices</b></big> - three interior circles
              corresponding to previous three days
              <ol type="a">
                <li>
                  Ordered from most recent (outside) to least recent (inside)
                </li>
                <li>
                  Previous Forecast Ranges - dark, light blue shades (50, 70%)
                  within interior circles
                </li>
                <li>
                  Previous Prices - black line within interior circles
                </li>
              </ol>
            </li>
            <br>
            <li>
              <big><b>Forecast Trend</b></big> - directional change in current forecast relative
              to three days prior
              <ol type="a">
                <li>
                  Directional change is measured on the basis of the forecasted
                  average price; green = forecast trending up,
                  red = forecast trending down
                </li>
              </ol>
            </li>
          </ol>
        </b-col>
        <b-col sm="3">
            <img
              src="@/assets/viterra_forecast_trend_1.png"
              alt="Forecast Trend 1"
              width="85%"
            >
            <img
              src="@/assets/viterra_forecast_trend_2.png"
              alt="Forecast Trend 2"
              width="85%"
            >
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col sm="6">
          <p>
            Users can access additional information regarding a forecast’s
            historical behavior via the dashboard’s Date Tool. By default,
            the dashboard will always display the most recent forecasts
            available, the date of which is found in the upper right header.
            Users have the ability to change the display settings to any date
            in the past (up to 2017-01-01), however, which will update the
            dashboard to reflect the data on that date. To do this, users can
            click on the "Setting" icon next to the present date, which will
            expand a calendar selection tool as shown below:
          </p>
          <img
            src="@/assets/viterra_date_selection.png"
            alt="Date Pickup"
            width="100%"
          >
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="3">
          <br>
          <p>
            By selecting a date in the past the <em>Present Forecast</em> dials will
            also contain another element - the actual realized prices
            relative to the historical forecasts. The orange dots and line
            indicate the realized prices and their average, respectively.
            The numeric average of prices is also detailed in the center
            of the dial
          </p>
          <p>
            This is a visual tool to inspect behavior at discrete points in
            time, but it is important to remember that any individual day is
            one sample out of thousands, and model performance should be
            assessed over the long run in order to draw appropriate conclusions.
          </p>
        </b-col>
        <b-col sm="3">
          <img
            src="@/assets/viterra_prices.png"
            alt="Prices"
            width="90%"
          >
        </b-col>
      </b-row>
      <b-row id="feature" class="mt-4">
        <b-col sm="6">
          <h4>Feature Importance Charts</h4>
          <p>
            The second main element of the dashboard - Feature Importance Charts -
            contains donut charts (one per target) highlighting the
            distribution of high-level feature categories that are most
            relevant in the predictive model's forecast over time.
          </p>
          <p>
            The feature categories consist of:
            <br><br>
            <ol>
              <li>
                <big><b>Target</b></big> - technical / statistical properties related to the
                target's (e.g. P1A W4, P6 W6) price history
              </li>
              <li>
                <big><b>Baltic Indices</b></big> - various composite / individual route prices
                across different segment vessels (Capesize, Panamax, etc)
              </li>
              <li>
                <big><b>Baltic Futures</b></big> - various composite / individual route futures
                prices across different vessel segments and time horizons
              </li>
              <li>
                <big><b>AIS</b></big> - engineered / aggregated vessel data
              </li>
              <li>
                <big><b>Metals / Materials</b></big>
                - futures and / or ETFs related to metals and materials commodities
              </li>
              <li>
                <big><b>Energy</b></big>
                - futures and / or ETFs related to energy commodities
              </li>
              <li>
                <big><b>Agriculture</b></big>
                - futures and / or ETFs related to agricultural commodities
              </li>
              <li>
                <big><b>Global Macro</b></big>
                - global macro indices, bond markets, treasury notes, etc
              </li>
            </ol>
          </p>
          <p>
            Below is an example of feature importance donut charts for P2A W4-6
          </p>
          <img
            src="@/assets/viterra_feature_importance.png"
            alt="Feature Importance"
            width="100%"
          >
          <p>
            It is important to note that within each category, hundreds of
            features are represented, including not only the raw feature
            itself (e.g. Copper M1 future price) but various transformations of the
            raw feature as well (e.g. technical analyses (moving average
            spreads), statistical analyses (decompositions), etc).
          </p>
          <p>
            Additionally, not all features in a category will affect the
            prediction in the same direction, and in many cases features
            within a category will have different coefficient signs
            (i.e. negative vs positive correlations). Therefore, it should
            not be interpreted, for example, that if a model that relies
            heavily on baltic futures this means that baltic futures and
            forecasts are heavily correlated in one direction - this is
            not the case.
          </p>
          <p>
            A more appropriate interpretation of the feature importance chart,
            for example, is to use it to assess market conditions that may be
            outside the predictive model's scope. For instance, if there is
            an event unfolding in the market that falls within the categories
            defined, the forecasts should be met with more assurance as the
            model has likely experienced / accounted for such relationships
            in the past, whereas events that happen outside the categories
            that are defined should be met with more caution.
          </p>
        </b-col>
      </b-row>
      <b-row id="performance" class="mt-4">
        <b-col sm="6">
          <h4>Model Performance Dials</h4>
          <p>
            The third main element of the dashboard - Model Performance Dials -
            contains three measures that assess different aspects of the
            predictive model's performance; these include:
            <br><br>
            <ol>
              <li>
                <big><b>Directional Accuracy</b></big> - represents whether the model correctly
                forecasted the average spot price per week up / down relative
                to average price of current week (i.e. is it getting direction
                right?)
              </li>
              <li>
                <big><b>Forecast Accuracy</b></big> - represents the error between the forecasted
                average spot price per week versus the actual average spot price
                per week (i.e. is the forecast error low?)
              </li>
              <li>
                <big><b>Range Accuracy</b></big> - represents the alignment between the range
                targets (50 and 70%), and the proportion of actual individual
                spot prices that fell within the predicted range(s) for the
                target week (i.e. is the forecasted range appropriate?)
              </li>
            </ol>
          </p>
          <p>
            The performance measures are expressed in two variations -
            <b><em>relative</em></b> and <b><em>absolute</em></b> -
            and rely on recent performance windows of either
            <b><em>six</em></b> or <b><em>twelve</em></b> months.
          </p>
          <p>
            The relative performance measures represent the percentile rank
            of the model’s recent performance relative to its total historical
            performance (2017-06 to present). In this way, we are making the
            interpretation of each metric standardized across targets as
            follows:
            <br><br>
            <ul>
              <li>
                0% = model is currently performing at its worst level ever
                regarding the metric in question
              </li>
              <li>
                50% = model is currently performing at its average level
                regarding the metric in question
              </li>
              <li>
                100% = model is currently performing at its best level ever
                regarding the metric in question
              </li>
            </ul>
          </p>
          <p>
            The relative performance dials identify (in red) when a metric
            is below 20% (i.e. model is currently performing at the lower
            end of its usual performance), and the following "rule of thumb"
            interpretations should be used to assess comprehensive model
            performance:
            <br><br>
            <ul>
              <li>
                All metrics above 20% = model behaving as expected
              </li>
              <li>
                1 metric below 20% = model may be starting to exhibit lower
                performance, but still valid
              </li>
              <li>
                2 metrics below 20% = model is starting to exhibit lower
                performance, use more caution in model outputs
              </li>
              <li>
                3 metrics below 20% = model is exhibiting lower performance
                than usual, worth a deeper investigation into model behavior,
                use caution
              </li>
            </ul>
          </p>
          <p>
            The absolute performance measures represent the nominal value of
            the various metrics in order to provide a discernible benchmark
            to relate relative performance figures towards. In this view of
            model performance the interpretation of each metric is as follows:
            <br><br>
            <ul>
              <li>
                Directional Accuracy = average directional accuracy
              </li>
              <li>
                Forecast Accuracy = 1 - median absolute percentage error (MedAPE)
              </li>
              <li>
                Range Accuracy  = 1 -  abs(difference between the target
                confidence interval (i.e. 50%) and percentage of actual values
                captured)
              </li>
            </ul>
            Therefore, similar to relative accuracy, in the absolute variation,
            the greater the metric the better the model performance.
          </p>
          <p>
            Below is an example of how the performance dials may be represented
            in the dashboard. Each circle represents a performance metric
            corresponding to the legend on top. The largest number within the
            circle is an average of the three performance metrics, with each
            individual performance metric listed below.
          </p>
          <img
            src="@/assets/viterra_model_performance.png"
            alt="Model Performance"
            width="100%"
          >
        </b-col>
      </b-row>
      <b-row id="ais" class="mt-4">
        <b-col sm="6">
          <h4>AIS Charts</h4>
          <p>
            The fourth main element of the dashboard - AIS Charts - contains
            various user options to produce time series visualizations for
            Panamax and Capesize AIS features based on vessel size, condition,
            and global location. The main types of AIS features include:
            <br><br>
            <ul>
              <li>
                <b>Vessel Count</b>
                - aggregated count of vessels of selected vessel size,
                condition, and location
              </li>
              <li>
                <b>Avg Speed</b>
                - average speed of vessels of selected vessel size, condition,
                and location
              </li>
              <li>
                <b>DWT</b>
                - aggregated deadweight sum of vessels of selected vessel size,
                condition, and location
              </li>
              <li>
                <b>Std. Score of DWT</b>
                - rolling 12 month z-score of aggregated deadweight sum of
                vessels of selected vessel size, condition, and location
              </li>
              <li>
                <b>Std. Score of Seasonal DWT</b>
                - rolling four year z-score by quarter of aggregated
                deadweight sum of vessels of selected vessel size,
                condition, and location
              </li>
            </ul>

            Users have the ability to select multiple AIS feature charts to
            view at once, as demonstrated in the example below.
          </p>
          <img
            src="@/assets/viterra_ais_2.png"
            alt="AIS Charts"
            width="100%"
          >
        </b-col>
      </b-row>
      <b-row id="review" class="mt-4">
        <b-col sm="6">
          <h4>Review</h4>
          <p>
            The dashboard contains information related to the predictive models
            forecasting forecast key physical routes and FFA markets.
          </p>
          <p>
            The main commercial elements of the dashboard are the forecast
            dials, however, such forecasts are supported with contextual model
            information related to the model's underlying features and its
            recent performance. In addition, AIS feature visualizations provide
            more market context.
          </p>
          <p>
            The dashboard is most useful when employed consistently as an input
            into decision processes with the understanding that while short term
            performance may fluctuate, the models deliver consistent
            forward-looking commercial insights / advantages over the long
            run - selective use of the dashboard may reduce / eliminate such
            advantages.
          </p>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'Documentation',
}
</script>
